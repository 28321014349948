import { ThemeProvider } from '@emotion/react'
import { ReactKeycloakProvider } from '@react-keycloak/web'
import React, { Suspense, useState } from 'react'
import { BrowserRouter, useRoutes } from 'react-router-dom'
import Cookie from 'universal-cookie'

import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'

import ToastMessage from '@/components/common/ToastMessage'
import GuestRoute from '@/components/guard/GuestRoutes'
import ProtectedRoute from '@/components/guard/ProtectedRoutes'
import ToastContext from '@/context/toastContext'
import useToastStore from '@/store/ToastStore'
import defautTheme from '@/theme/themes'

import { AuthContextProvider } from './context/AuthContextProvider'
import keycloakAuthClient from './keycloak'
import Holiday from './view/Holiday'
import Overloaded from './view/Overloaded'

const Login = React.lazy(() => import('@/view/Login'))
const Planner = React.lazy(() => import('@/view/Planner3'))
const Clients = React.lazy(() => import('@/view/Clients'))
const Projects = React.lazy(() => import('@/view/Projects'))
const ProjectDetail = React.lazy(() => import('@/view/Projects/ProjectDetail'))
const Resources = React.lazy(() => import('@/view/Resources'))
const Departments = React.lazy(() => import('@/view/Departments'))
const BusinessUnits = React.lazy(() => import('@/view/BusinessUnit'))
const Roles = React.lazy(() => import('@/view/Roles'))

const MainView = () => {
  const routes = useRoutes([
    {
      path: '/',
      element: (
        <ProtectedRoute isPlanner={true}>
          <Planner />
        </ProtectedRoute>
      ),
      index: true,
    },
    {
      path: '/resources',
      children: [
        {
          path: ':id',
          element: (
            <ProtectedRoute>
              <Resources />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Resources />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/overloaded',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute roleAllowed={['ADMIN']}>
              <Overloaded />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/projects',
      children: [
        {
          path: ':id',
          element: (
            <ProtectedRoute>
              <ProjectDetail />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Projects />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/clients',
      children: [
        {
          path: ':id',
          element: (
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          ),
        },
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Clients />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/holiday',
      children: [
        {
          path: '',
          element: (
            <ProtectedRoute>
              <Holiday />
            </ProtectedRoute>
          ),
        },
      ],
    },
    {
      path: '/departments',
      element: (
        <ProtectedRoute>
          <Departments />
        </ProtectedRoute>
      ),
    },
    {
      path: '/teams',
      element: (
        <ProtectedRoute>
          <BusinessUnits />
        </ProtectedRoute>
      ),
    },
    {
      path: '/roles',
      element: (
        <ProtectedRoute>
          <Roles />
        </ProtectedRoute>
      ),
    },
    {
      path: '/login',
      element: (
        <GuestRoute>
          <Login />
        </GuestRoute>
      ),
    },
    { path: '*', element: <div>Not found</div> },
  ])
  return routes
}

function App() {
  const { state, dispatch } = useToastStore()
  const [keycloakInitialized, setKeycloakInitialized] = useState(false) // State to track Keycloak initialization
  const cookies = new Cookie()

  const handleOnToken = (token) => {
    cookies.set('accessToken', token.token)
    cookies.set('refreshToken', token.refreshToken)
    setKeycloakInitialized(true) // Set state to true once tokens are set
  }

  const handleKeycloakEvent = (event, error) => {
    if (event === 'onAuthSuccess' || event === 'onReady') {
      setKeycloakInitialized(true) // Set state when Keycloak is ready
    }
    if (event === 'onAuthLogout') {
      setKeycloakInitialized(false) // Reset state on logout
    }
  }

  return (
    <ReactKeycloakProvider
      authClient={keycloakAuthClient}
      onTokens={handleOnToken}
      onEvent={handleKeycloakEvent}
      initOptions={{
        onLoad: 'login-required',
        checkLoginIframe: false,
      }}
    >
      <AuthContextProvider>
        <ToastContext.Provider
          value={{
            state,
            dispatch,
          }}
        >
          <ToastMessage />

          <ThemeProvider theme={defautTheme}>
            <BrowserRouter>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                {keycloakInitialized ? (
                  <Suspense
                    fallback={
                      <Backdrop
                        sx={{
                          color: '#fff',
                          zIndex: (t) => t.zIndex.drawer + 1,
                        }}
                        open
                      >
                        <CircularProgress color="primary" />
                      </Backdrop>
                    }
                  >
                    <MainView />
                  </Suspense>
                ) : (
                  // Show loading indicator until Keycloak is initialized
                  <Backdrop
                    sx={{
                      color: '#fff',
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open
                  >
                    <CircularProgress color="primary" />
                  </Backdrop>
                )}
              </LocalizationProvider>
            </BrowserRouter>
          </ThemeProvider>
        </ToastContext.Provider>
      </AuthContextProvider>
    </ReactKeycloakProvider>
  )
}

export default App
