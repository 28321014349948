import * as moment from 'moment'
import React, { useEffect, useState } from 'react'

import { CheckBox, CloseOutlined, Folder } from '@mui/icons-material'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import {
  Avatar,
  Box,
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogTitle,
  Icon,
  IconButton,
} from '@mui/material'

import overloadApi from '@/api/overload'
import Loading from '@/components/common/Loading'
import { BACK_DOMAIN } from '@/constants/env'
import { useAsyncRequest } from '@/utils/actionWrapper'

import ConfirmDialog from './components/ConfirmDialog'
import ExportButton from './components/ExportButton'
import InputLimit from './components/LimitInput'

export default function Overloaded() {
  const [isExpand, setIsExpand] = useState(false)
  const [acitveKey, setActiveKey] = useState(-1)
  const [value, setValue] = useState('')
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false)
  const [openSuccessDialog, setOpenSuccessDialog] = useState(false)

  const [chosenOption, setChosenOption] = useState('')
  const [overloads, setOverloads] = useState([])
  const { actionWrapper } = useAsyncRequest()
  const [selectedItem, setSelectedItem] = useState(null)
  const [generatedFile, setGeneratedFile] = useState(null)
  const [loading, setLoading] = useState(true)
  const [showApprove, setShowApprove] = useState(false)

  const handleClickOpen = () => {
    setOpenConfirmDialog(true)
  }
  const handleCloseCancel = () => {
    setOpenConfirmDialog(false)
    setSelectedItem(null)
  }
  const handleView = async () => {
    window.open(
      `https://docs.google.com/spreadsheets/d/${generatedFile}/edit#gid=0`,
      'new',
    )
  }
  function handleCloseDialog() {
    setOpenSuccessDialog(false)
  }
  const handleClose = () => {
    setOpenConfirmDialog(false)
    if (chosenOption === 'deny') {
      handleDelete()
    } else if (chosenOption === 'approve') {
      handleApprove()
    }
  }
  const handleApprove = async () => {
    await actionWrapper(
      () =>
        overloadApi.update(selectedItem, {
          overloadPercentage: `${value}`,
          hasApproved: true,
        }),
      {
        showMessage: true,
      },
    )
    fetchOverloads()
  }
  const handleDelete = async () => {
    await actionWrapper(() => overloadApi.delete(selectedItem), {
      showMessage: true,
    })
    setOverloads(overloads.filter((item) => item.id !== selectedItem))
  }

  const fetchOverloads = async () => {
    const res = await overloadApi.get()
    setOverloads(res)
    setLoading(false)
  }

  useEffect(() => {
    fetchOverloads()
  }, [])

  return (
    <>
      <ExportButton
        setGeneratedFile={setGeneratedFile}
        setOpenSuccessDialog={setOpenSuccessDialog}
        loading={loading}
        setLoading={setLoading}
      />
      <div className=" flex my-auto">
        <strong className=" my-auto">SHOW APPROVED</strong>
        <Checkbox
          checked={showApprove}
          onChange={(e) => setShowApprove(e.target.checked)}
        />
      </div>
      {overloads.length > 0 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            {overloads
              .filter((item) => !item?.hasApproved || showApprove)
              .map((item, index) => (
                <Box
                  key={item.id}
                  sx={{
                    marginBottom: '20px',
                    width: '100%',
                    color: item?.hasApproved ? 'gray' : 'black',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      width: '100%',
                      padding: '20px 48px 20px 48px',
                      boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                      borderRadius: '4px',
                    }}
                  >
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        marginBottom: '10px',
                        padding: '10px 0 10px 0',
                        borderBottom: '1px solid #F3F3F3',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          justifyContent: 'flex-start',
                          alignItems: 'center',
                          fontSize: '18px',
                        }}
                      >
                        <p>
                          Project: <strong>{item.Project.projectName}</strong>
                        </p>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          gap: '20px',
                        }}
                      >
                        <Box
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '16px',
                          }}
                        >
                          <InputLimit
                            setValue={setValue}
                            value={item.overloadPercentage || ''}
                            percentage_diff={
                              ((item.willBeSpentBudget - item.budget) * 100) /
                              item.budget
                            }
                          />
                          <button
                            onClick={() => {
                              setChosenOption('approve')
                              setSelectedItem(item.id)

                              handleClickOpen()
                            }}
                            disabled={value === '' || item?.hasApproved}
                            style={{
                              background: '#039487',
                              minWidth: '80px',
                              height: '39px',
                              padding: '10px',
                              borderRadius: '4px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: 'white',
                              opacity: value === '' ? 0.5 : 1,
                              cursor: value === '' ? 'not-allowed' : 'pointer',
                            }}
                          >
                            {!item?.hasApproved ? 'Approve' : 'Update'}
                          </button>
                          <button
                            disabled={item?.hasApproved}
                            onClick={() => {
                              setChosenOption('deny')
                              setSelectedItem(item.id)

                              handleClickOpen()
                            }}
                            style={{
                              background: '#FF7373',
                              minWidth: '80px',
                              height: '39px',
                              padding: '10px',
                              borderRadius: '4px',
                              display: 'flex',
                              justifyContent: 'center',
                              alignItems: 'center',
                              color: 'white',
                            }}
                          >
                            Deny
                          </button>
                          <ConfirmDialog
                            option={chosenOption}
                            handleClose={handleClose}
                            handleCloseCancel={handleCloseCancel}
                            open={openConfirmDialog}
                            value={value}
                            projectName={item.Project.projectName}
                          />
                        </Box>
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        gap: '20px',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 'fit-content',
                          gap: '5px',
                          textAlign: 'right',
                        }}
                      >
                        <p>
                          <strong>FTE(ETP):</strong>
                        </p>
                        <p>
                          <strong>Days:</strong>
                        </p>
                        <p>
                          <strong>Repeats:</strong>
                        </p>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 'fit-content',
                          gap: '5px',
                        }}
                      >
                        <p>{item.etp}</p>
                        <p>
                          {moment(item?.startDate).format('DD/MM/YYYY')} -{' '}
                          {moment(item?.endDate).format('DD/MM/YYYY')}
                        </p>
                        {item?.repeatType && (
                          <p>
                            {item?.repeatType} Until{' '}
                            {moment(item?.repeatUntil).format('DD/MM/YYYY')}
                          </p>
                        )}
                      </Box>
                      <Box
                        sx={{
                          marginLeft: '50px',
                          display: 'flex',
                          flexDirection: 'column',
                          width: 'fit-content',
                          gap: '5px',
                          textAlign: 'right',
                        }}
                      >
                        <p>
                          <strong>BUDGET:</strong>
                        </p>
                        <p>
                          <strong>Overloaded:</strong>
                        </p>
                      </Box>
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'column',
                          width: 'fit-content',
                          gap: '5px',
                        }}
                      >
                        <p>{item.budget} €</p>
                        <p>{item.willBeSpentBudget} €</p>
                        <hr />
                        <p className="grayscale ">
                          +
                          {(
                            ((item.willBeSpentBudget - item.budget) * 100) /
                            item.budget
                          ).toFixed(2)}{' '}
                          %
                        </p>
                      </Box>
                    </Box>
                    <Box
                      onClick={() => {
                        setIsExpand(!isExpand)
                        if (isExpand) {
                          setActiveKey(-1)
                        } else {
                          setActiveKey(index)
                        }
                      }}
                      sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'flex-end',
                      }}
                    >
                      <p>
                        <strong>{item.Project.ResourceOnProjectlength}</strong>{' '}
                        Resources
                      </p>
                      <ArrowDropDownIcon
                        fontSize="medium"
                        sx={{
                          transition: 'all 0.3s ease',
                          transform:
                            acitveKey === index
                              ? 'rotate(180deg)'
                              : 'rotate(0deg)',
                        }}
                      />
                    </Box>
                  </Box>
                  <Collapse in={acitveKey === index} sx={{ width: '100%' }}>
                    <Box
                      sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        width: '100%',
                      }}
                    >
                      <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          borderTop: 'none',
                          borderRadius: '0px 0px 8px 8px',
                          width: '90%',
                          padding: '20px 48px 20px 48px',
                          boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
                        }}
                      >
                        <Box flex={'row'} width={'100%'}>
                          {item.Project.ResourceOnProject.map(
                            (resource, index) => (
                              <Box
                                key={`${resource.Resource.id}${item.Project.id}${index}`}
                                className=" space-y-3 border-b-gray border-b-2 w-full"
                                sx={{
                                  display: 'flex',
                                  flexDirection: 'row',
                                  justifyContent: 'flex-start',
                                  alignItems: 'center',
                                  gap: '20px',
                                }}
                              >
                                <Box
                                  sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    width: '80px',
                                    height: '80px',
                                  }}
                                >
                                  {resource.Resource?.profileImage ? (
                                    <Avatar
                                      src={`${BACK_DOMAIN}/${resource.Resource?.profileImage}`}
                                      sx={{
                                        width: 40,
                                        height: 40,
                                      }}
                                    />
                                  ) : (
                                    <Avatar
                                      sx={{
                                        bgcolor: resource.Resource?.color,
                                        width: 40,
                                        height: 40,
                                      }}
                                    >
                                      {resource.Resource?.fullName.split(' ')
                                        .length > 1
                                        ? resource.Resource?.fullName
                                            .split(' ')
                                            .shift()
                                            .charAt(0) +
                                          resource.Resource?.fullName
                                            .split(' ')
                                            .pop()
                                            .charAt(0)
                                        : resource.Resource?.fullName.charAt(0)}
                                    </Avatar>
                                  )}
                                </Box>
                                <Box
                                  sx={{
                                    width: 'fit-content',
                                    // textAlign: "right",
                                  }}
                                >
                                  <p>
                                    <strong>Resource Name:</strong>
                                  </p>
                                  <p>
                                    <strong>Position:</strong>
                                  </p>
                                  <p>
                                    <strong>Department:</strong>
                                  </p>
                                </Box>
                                <Box
                                  sx={{
                                    minWidth: '400px',
                                    width: 'fit-content',
                                  }}
                                >
                                  <p>{resource.Resource.fullName}</p>
                                  <p>
                                    {resource.Resource?.Role.name ||
                                      'Undefined'}
                                  </p>
                                  <p>
                                    {resource.Resource?.EmployeeDepartment
                                      .departmentName || 'Undefined'}
                                  </p>
                                </Box>
                              </Box>
                            ),
                          )}
                        </Box>
                      </Box>
                    </Box>
                  </Collapse>
                </Box>
              ))}
          </Box>
        </>
      ) : (
        <Box className="text-center mt-10 font-bold text-2xl w-full">
          No Overload
        </Box>
      )}
      {loading && <Loading />}

      <Dialog open={openSuccessDialog}>
        <div className="p-5">
          <DialogTitle>
            Overload Project Export
            <IconButton
              aria-label="close"
              onClick={handleCloseDialog}
              sx={{
                position: 'absolute',
                right: 8,
                top: 8,
              }}
            >
              <CloseOutlined />
            </IconButton>
          </DialogTitle>
          <div className="flex justify-center p-5 space-x-4">
            <Button onClick={() => handleView()}>
              <Folder name={' Export'} />
            </Button>
          </div>
        </div>
      </Dialog>
    </>
  )
}
