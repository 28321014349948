import axiosInstance, { logout } from '@/utils/axiosInstance'

const useAuth = () => {
  const getCurrentUser = async () => {
    try {
      const res = await axiosInstance.get('/auth/currentLoginUser')
      return res.data
    } catch (error) {
      console.log('Error:', error)
    }
  }

  return {
    getCurrentUser,
  }
}

export { useAuth }
