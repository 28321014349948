import PropTypes from 'prop-types'
import React, { useContext, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import AdminLayout from '@/components/layout/AdminLayout'
import { AuthContext } from '@/context/AuthContextProvider'

import PlannerLayout from '../layout/PlannerLayout'

function ProtectedRoute({ children, roleAllowed, isPlanner = false }) {
  const { user } = useContext(AuthContext)
  const navigate = useNavigate()

  const redirectBackToHome = () => {
    navigate('/')
  }

  useEffect(() => {
    if (user && roleAllowed) {
      if (
        !user?.platform?.allocation ||
        (roleAllowed?.length > 0 &&
          !roleAllowed?.includes(user?.platform?.allocation.toUpperCase()))
      ) {
        redirectBackToHome()
      }
    }
  }, [user])

  return (
    <div>
      {isPlanner ? (
        <PlannerLayout>{children}</PlannerLayout>
      ) : (
        <AdminLayout>{children}</AdminLayout>
      )}
    </div>
  )
}

ProtectedRoute.propTypes = {
  children: PropTypes.any.isRequired,
}

export default ProtectedRoute
